import { inject }                         from 'aurelia-framework';
import { BooleanOption }                  from 'modules/administration/models/boolean-option';
import { GeosigPhasesRepository }         from 'modules/management/concessions/concessions-tree/geosig-phases/services/repository';
import { LotInterventionTypesRepository } from 'modules/management/concessions/concessions-tree/lot-intervention-types/services/repository';
import { LotIntervention }                from 'modules/management/models/lot-intervention';
import { LotInterventionType }            from 'modules/management/models/lot-intervention-type';

@inject(GeosigPhasesRepository, LotInterventionTypesRepository)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        make_geosig_available_id: BooleanOption.NO,
        closed_id:                BooleanOption.NO,
    };

    /**
     * Constructor
     *
     * @param geosigPhasesRepository
     * @param lotInterventionTypesRepository
     */
    constructor(geosigPhasesRepository, lotInterventionTypesRepository) {
        this.geosigPhasesRepository         = geosigPhasesRepository;
        this.lotInterventionTypesRepository = lotInterventionTypesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {LotIntervention}
     */
    model() {
        let model = new LotIntervention();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.lot_intervention_type_id = {
            type:         'select2',
            key:          'lot_intervention_type_id',
            label:        'form.field.type',
            size:         6,
            required:     false,
            remoteSource: () => this.lotInterventionTypesRepository.all(),
            attributes:   {
                disabled: readonly,
            },
            observers:    [
                (newValue, oldValue) => {
                    if (newValue && newValue === LotInterventionType.INTERVENTION) {
                        // TODO
                    }
                },
            ],
        };

        this.number = {
            type:       'text',
            key:        'number',
            label:      'form.field.number',
            size:       6,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.code = {
            type:       'text',
            key:        'code',
            label:      'form.field.code',
            size:       6,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        };

        this.geosig_phase_id = {
            type:         'select2',
            key:          'geosig_phase_id',
            label:        'form.field.geosig-phase',
            size:         6,
            required:     false,
            remoteSource: () => this.geosigPhasesRepository.all(),
            attributes:   {
                disabled: readonly,
            },
        };

        this.make_geosig_available_id = {
            type:       'boolean-options-select',
            key:        'make_geosig_available_id',
            label:      'form.field.make-geosig-available',
            size:       6,
            required:   false,
            attributes: {
                disabled: readonly,
            },
        };

        this.designation = {
            type:       'text',
            key:        'designation',
            label:      'form.field.designation',
            size:       6,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.start_date = {
            type:            'material-ui-date-picker',
            key:             'start_date',
            label:           'form.field.start-date',
            size:            6,
            required:        false,
            valueConverters: [
                { name: 'dateFormat' },
            ],
            attributes:      {
                disabled: readonly,
            },
            observers:    [
                (newValue, oldValue) => {
                    if (newValue && viewModel.model.lot_intervention_type_id === LotInterventionType.INTERVENTION) {
                        // TODO
                    }
                },
            ],
        };

        this.finish_date = {
            type:            'material-ui-date-picker',
            key:             'finish_date',
            label:           'form.field.finish-date',
            size:            6,
            required:        false,
            valueConverters: [
                { name: 'dateFormat' },
            ],
            attributes:      {
                disabled: readonly,
            },
        };

        this.closed_id = {
            type:       'boolean-options-select',
            key:        'closed_id',
            label:      'form.field.close-lot',
            size:       6,
            required:   false,
            attributes: {
                disabled: readonly,
            },
        };

        const schema = [
            [this.lot_intervention_type_id, this.number],
            [this.code, this.designation],
            [this.geosig_phase_id, this.make_geosig_available_id],
            [this.start_date, this.finish_date],
            [this.closed_id],
        ];

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            return [...schema, [this.created_by, this.created_at]];
        }

        return schema;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('management.concessions.concessions.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
